import { createReducer, on } from '@ngrx/store';
import * as StbActions from './stb.actions';

export const stbFeatureKey = 'stb';

export interface StbState {
  currentInputType: string;
  currentInputIndex: number;
  cpuUsage: number;
  memoryUsage: number;
}

const initialState: StbState = {
  currentInputType: undefined,
  currentInputIndex: undefined,
  cpuUsage: 0,
  memoryUsage: 0,
};

export const stbReducer = createReducer<StbState>(
  initialState,
  on(StbActions.setCurrentInput, (state, action) => ({
    ...state,
    currentInputType: action.typ,
    currentInputIndex: action.index,
  })),
  on(StbActions.getCpuUsageSuccess, (state, action) => ({
    ...state,
    cpuUsage: action.percentage,
  })),
  on(StbActions.getMemoryUsageSuccess, (state, action) => ({
    ...state,
    memoryUsage: action.percentage,
  }))
);
