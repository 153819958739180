import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PusherService } from './pusher.service';
import { State } from 'src/app/state/root.reducer';
import { Store } from '@ngrx/store';
import * as RootActions from 'src/app/state/root.actions';
import * as MessagesActions from 'src/app/state/messages.actions';
import * as StbActions from 'src/app/state/stb.actions';
import { MessageRecipientNotification } from 'src/app/state/messages.reducer';

@Injectable()
export class StbChannelService {
  private messageRecipientRevokedSource = new Subject();

  messageRecipientRevoked$ = this.messageRecipientRevokedSource.asObservable();

  constructor(private pusher: PusherService, private store$: Store<State>) {}

  public subscribe(macAddress: string) {
    const channelName = `private-stb-${macAddress.replace(/:/g, '-')}`;
    let channel = this.pusher.subscribe(channelName);

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_succeeded',
      () => {
        console.info(`Private STB channel subscribed: ${channelName}`);
        this.store$.dispatch(
          RootActions.subscribeStbChannelSuccess({ channelName })
        );
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_error',
      (status) => {
        this.store$.dispatch(RootActions.subscribeStbChannelFailure());
        console.error(
          `Private STB channel subscription failed: ${channelName}`
        );
        console.error(status);
      }
    );

    // bind to message_recipient_created event
    this.pusher.bindChannelEvent(
      channel,
      'message_recipient_created',
      (notification: MessageRecipientNotification) =>
        this.store$.dispatch(
          MessagesActions.messageRecipientCreated(notification)
        )
    );

    // bind to message_recipient_updated event
    this.pusher.bindChannelEvent(
      channel,
      'message_recipient_updated',
      (notification: MessageRecipientNotification) =>
        this.store$.dispatch(
          MessagesActions.messageRecipientUpdated(notification)
        )
    );

    // bind to set_top_box_updated event
    this.pusher.bindChannelEvent(channel, 'set_top_box_updated', (data) =>
      this.store$.dispatch(StbActions.stbUpdated({ setTopBox: data }))
    );

    // bind to av_input_updated
    this.pusher.bindChannelEvent(channel, 'av_input_updated', (data) =>
      this.store$.dispatch(StbActions.avInputUpdated())
    );

    // bind to stb_client_reload
    this.pusher.bindChannelEvent(channel, 'stb_client_reload', (data) =>
      this.store$.dispatch(StbActions.reload())
    );

    // bind to stb_client_reset
    this.pusher.bindChannelEvent(channel, 'stb_client_reset', (data) => {
      console.log(`stb_client_reset received: ${JSON.stringify(data)}`);
      this.store$.dispatch(StbActions.reset());
    });

    // bind to stb_client_reboot
    this.pusher.bindChannelEvent(channel, 'stb_client_reboot', (data) =>
      this.store$.dispatch(StbActions.reboot())
    );

    // bind to stb_client_standby
    this.pusher.bindChannelEvent(channel, 'stb_client_standby', (data) =>
      this.store$.dispatch(StbActions.standby())
    );

    // bind to stb_client_power_standby
    this.pusher.bindChannelEvent(channel, 'stb_client_power_standby', (data) =>
      this.store$.dispatch(StbActions.standby())
    );

    // bind to stb_client_power_warm
    this.pusher.bindChannelEvent(channel, 'stb_client_power_warm', (data) =>
      this.store$.dispatch(StbActions.setPowerWarm())
    );

    // bind to stb_client_power_normal
    this.pusher.bindChannelEvent(channel, 'stb_client_power_normal', (data) =>
      this.store$.dispatch(StbActions.setPowerNormal())
    );

    // bind to stb_check_sunrise_update
    // this.pusher.bindChannelEvent(
    //   channel,
    //   'stb_check_sunrise_update',
    //   this.handleStbCheckSunriseUpdate.bind(this)
    // );

    // bind to stb_get_logs
    // this.pusher.bindChannelEvent(
    //   channel,
    //   'stb_get_logs',
    //   this.handleStbGetLogs.bind(this)
    // );

    // bind to stb_reset_logs
    // this.pusher.bindChannelEvent(
    //   channel,
    //   'stb_reset_logs',
    //   this.handleStbResetLogs.bind(this)
    // );

    // bind to stb_toggle_logging
    // this.pusher.bindChannelEvent(
    //   channel,
    //   'stb_reset_logs',
    //   this.handleStbToggleLogging.bind(this)
    // );

    // bind to stb_report_sunrise_version
    // this.pusher.bindChannelEvent(
    //   channel,
    //   'stb_report_sunrise_version',
    //   this.handleStbReportSunriseVersion.bind(this)
    // );

    // bind to stb_report_messenger_version
    this.pusher.bindChannelEvent(
      channel,
      'stb_report_messenger_version',
      (data) => this.store$.dispatch(StbActions.stbReportMessengerVersion())
    );
  }

  public unsubscribe(channelName: string): void {
    this.pusher.unsubscribe(channelName);
    console.info('Unsubscribed private STB channel');
  }

  // private handleSetTopBoxUpdated(setTopBoxJSON: string) {
  //   let setTopBox = JSON.parse(setTopBoxJSON);
  //   this.configuration.setTopBox = setTopBox;
  //   this.configuration.save();
  //   this.logger.info(`set_top_box update received`);

  //   /*
  //    * Also set loggingEnabled in localStorage
  //    * Required until i can figure what we are doing with logging here
  //    */
  //   this.storage.store(
  //     'loggingEnabled',
  //     this.configuration.setTopBox.logging_enabled
  //   );
  // }

  // private handleAvInputUpdated(avInputJSON: string) {
  //   // Change inputs if this is active and different from the currently
  //   // active input
  //   let avInput = JSON.parse(avInputJSON);
  //   let activeInput = find(
  //     this.configuration.setTopBox.av_inputs,
  //     (avInput) => avInput.active === true
  //   );

  //   if (avInput.active && (!activeInput || avInput.id !== activeInput.id)) {
  //     this.logger.info('setting new input: ' + avInput.label);
  //     this.logger.warn(`AV Input update in HCAP not implemented`);
  //   }

  //   let index = findIndex(
  //     this.configuration.setTopBox.av_inputs,
  //     function (input) {
  //       return input.id === avInput.id;
  //     }
  //   );

  //   if (index !== -1) {
  //     this.configuration.setTopBox.av_inputs[index] = avInput;
  //     this.configuration.save();
  //   }
  // }

  // private handleStbClientReload(stbClientReloadJSON: string) {
  //   this.logger.info('stb-channel : event received : stb_client_reload');
  //   let stbClientReload = JSON.parse(stbClientReloadJSON);
  //   if (stbClientReload.force) {
  //     this.logger.info(
  //       'stb_client_reload : force=True : reloading client regardless of state'
  //     );
  //     this.setTopBox.reload();
  //   } else {
  //     if (this.route.toString() === 'supervisor/listener') {
  //       this.logger.info(
  //         `stb_client_reload : currently in listening mode, accepting reload request`
  //       );
  //       this.setTopBox.reload();
  //     } else {
  //       this.logger.warn(
  //         `stb_client_reload : not currently in listening mode, rejecting reload request`
  //       );
  //     }
  //   }
  // }

  // private handleStbClientReset(stbClientResetJSON: string) {
  //   this.logger.info('stb-channel : event received : stb_client_reset');
  //   let stbClientReset = JSON.parse(stbClientResetJSON);
  //   if (stbClientReset.force) {
  //     this.logger.warn('stb_client_reset : force=true : actioning immediately');
  //     this.setTopBox.reset();
  //   } else {
  //     // force=False
  //     // TODO: Need to throw a dialog here asking for user acceptance i guess
  //     // for now will just ignore request and log.
  //     this.logger.warn(
  //       'stb_client_reset : force=false : ignoring request, confirm dialog not implemented'
  //     );
  //   }
  // }

  // private handleStbClientReboot(stbClientRebootJSON: string) {
  //   this.logger.info('Pusher : event received : stb_client_reboot');
  //   var stbClientReboot = JSON.parse(stbClientRebootJSON);
  //   if (stbClientReboot.force) {
  //     this.logger.warn(
  //       'stb_client_reboot : force=true : actioning immediately'
  //     );
  //     // Rebooting....
  //     this.setTopBox.reboot();
  //   } else {
  //     // force=False
  //     // TODO: Need to throw a dialog here asking for user acceptance i guess
  //     // for now will just ignore request and log.
  //     this.logger.warn(
  //       'stb_client_reboot : force=false : ignoring request, confirm dialog not implemented'
  //     );
  //   }
  // }

  // private handleStbCheckSunriseUpdate(stbCheckSunriseUpdateJSON: string) {
  //   let stbCheckSunriseUpdate = JSON.parse(stbCheckSunriseUpdateJSON); // This will be empty for now
  //   this.logger.info('stb-channel : event received : stb_check_sunrise_update');
  //   this.setTopBox.checkForSunriseUpdate();
  // }

  // private handleStbGetLogs() {
  //   // TODO: once i figure our what to do about logging
  // }

  // private handleStbResetLogs() {
  //   // TODO: once i figure our what to do about logging
  // }

  // private handleStbToggleLogging() {
  //   // TODO: once i figure our what to do about logging
  // }

  // private handleStbReportSunriseVersion(stbReportSunriseVersionJSON: string) {
  //   let stbReportSunriseVersion = JSON.parse(stbReportSunriseVersionJSON); // This will empty for now
  //   this.logger.info(
  //     'stb-channel : event received : stb_report_sunrise_version'
  //   );
  //   this.setTopBox.reportCurrentSunriseVersion();
  // }

  // private handleStbReportMessengerVersion(
  //   stbReportMessengerVersionJSON: string
  // ) {
  //   let stbReportMessengerVersion = JSON.parse(stbReportMessengerVersionJSON); // This will empty for now
  //   this.logger.info(
  //     'stb-channel : event received : stb_report_messenger_version'
  //   );
  //   this.setTopBox.reportCurrentMessengerVersion();
  // }
}
