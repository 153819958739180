import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from './shared/shared.module';
import { JwtModule } from '@auth0/angular-jwt';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { ROOT_REDUCERS, metaReducers } from './state/root.reducer';

import { UserEffects } from './state/user.effect';
import { RootEffects } from './state/root.effects';
import { MessagesEffects } from './state/messages.effects';
import { ConferencingEffects } from './state/conferencing.effects';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { StbEffects } from './state/stb.effects';
import { PresenceEffects } from './state/presence.effects';
import { Replay } from '@sentry/replay';

Sentry.init({
  dsn: 'https://a9c0d648168d4d36b865cd9b698733d8@o409495.ingest.sentry.io/5450481',
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'https://tv.caremessenger.co.uk',
        'https://tv-staging.caremessenger.co.uk',
        'https://tv-dev.caremessenger.co.uk',
        'https://tv-demo.caremessenger.co.uk',
        'https://stb-dev.caremessenger.co.uk',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.env === 'prod' ? 0.3 : 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: environment.env === 'prod' ? 0.3 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

// ngx-translate loader
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    HttpClientModule,
    MomentModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost', 'caremessenger.co.uk', '172.17.10.222'],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(ROOT_REDUCERS, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      UserEffects,
      RootEffects,
      ConferencingEffects,
      MessagesEffects,
      StbEffects,
      PresenceEffects,
    ]),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
