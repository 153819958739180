import { LoggerService } from './logger.service';
/* tslint =disable =no-bitwise */

import { Injectable, OnDestroy } from '@angular/core';
import { State } from 'src/app/state/root.reducer';
import { Store } from '@ngrx/store';
import { Resident } from '../models/resident.model';
import { Observable, Subscription } from 'rxjs';

import * as UserSelectors from '../../state/user.selectors';

export enum ASCII_REMOTE_KEYS {
  // back = 8, // for browser
  back = 461, // for TV
  ok = 13,
  left = 37,
  up = 38,
  right = 39,
  down = 40,
  zero = 48,
  one = 49,
  two = 50,
  three = 51,
  four = 52,
  five = 53,
  six = 54,
  seven = 55,
  eight = 56,
  nine = 57,
  epg = 458,
  info = 457, // for tv
  // info = 83, // for browser
  text = 84,
  subtitles = 83,
  red = 403,
  green = 404, // for tv
  // green = 71, // for browser
  yellow = 405,
  blue = 406, // for TV
  // blue = 66, // for browser
  av = 712,
  menu = 18,
  power = 409,
  channel_up = 427,
  channel_down = 428,
  exit = 1001,
}

export enum REMOTE_KEYS {
  key_ok = 0x00000001, // Enter
  key_left = 0x00000002, // Left
  key_right = 0x00000004, // Right
  key_up = 0x00000008, // Up
  key_down = 0x00000010, // Down
  key_0 = 0x00000020, // 0
  key_1 = 0x00000040, // 1
  key_2 = 0x00000080, // 2
  key_3 = 0x00000100, // 3
  key_4 = 0x00000200, // 4
  key_5 = 0x00000400, // 5
  key_6 = 0x00000800, // 6
  key_7 = 0x00001000, // 7
  key_8 = 0x00002000, // 8
  key_9 = 0x00004000, // 9
  key_epg = 0x00008000, // E
  key_info = 0x00010000, // I
  key_text = 0x00020000, // T
  key_subtitles = 0x00040000, // S
  key_back = 0x00080000, // Backspace
  key_red = 0x00100000, // R
  key_green = 0x00200000, // G
  key_yellow = 0x00400000, // Y
  key_blue = 0x00800000, // B
  key_av = 0x01000000, // A
  key_menu = 0x02000000, // M
  key_power = 0x04000000, // P
  key_channel_up = 0x08000000, // +
  key_channel_down = 0x10000000, // -
}

export enum REMOTE_KEY_SETS {
  ok = REMOTE_KEYS.key_ok,

  ok_arrows = REMOTE_KEYS.key_ok |
    REMOTE_KEYS.key_up |
    REMOTE_KEYS.key_down |
    REMOTE_KEYS.key_left |
    REMOTE_KEYS.key_right,

  ok_arrows_back = REMOTE_KEYS.key_ok |
    REMOTE_KEYS.key_up |
    REMOTE_KEYS.key_down |
    REMOTE_KEYS.key_left |
    REMOTE_KEYS.key_right |
    REMOTE_KEYS.key_back,

  ok_arrows_back_colours = REMOTE_KEYS.key_ok |
    REMOTE_KEYS.key_back |
    REMOTE_KEYS.key_up |
    REMOTE_KEYS.key_down |
    REMOTE_KEYS.key_left |
    REMOTE_KEYS.key_right |
    REMOTE_KEYS.key_red |
    REMOTE_KEYS.key_yellow |
    REMOTE_KEYS.key_green |
    REMOTE_KEYS.key_blue,

  numbers = REMOTE_KEYS.key_0 |
    REMOTE_KEYS.key_1 |
    REMOTE_KEYS.key_2 |
    REMOTE_KEYS.key_3 |
    REMOTE_KEYS.key_4 |
    REMOTE_KEYS.key_5 |
    REMOTE_KEYS.key_6 |
    REMOTE_KEYS.key_7 |
    REMOTE_KEYS.key_8 |
    REMOTE_KEYS.key_9,

  ok_arrows_numbers = REMOTE_KEYS.key_ok |
    REMOTE_KEYS.key_back |
    REMOTE_KEYS.key_up |
    REMOTE_KEYS.key_down |
    REMOTE_KEYS.key_left |
    REMOTE_KEYS.key_right |
    REMOTE_KEYS.key_0 |
    REMOTE_KEYS.key_1 |
    REMOTE_KEYS.key_2 |
    REMOTE_KEYS.key_3 |
    REMOTE_KEYS.key_4 |
    REMOTE_KEYS.key_5 |
    REMOTE_KEYS.key_6 |
    REMOTE_KEYS.key_7 |
    REMOTE_KEYS.key_8 |
    REMOTE_KEYS.key_9,
}

@Injectable()
export class RemoteControlService implements OnDestroy {
  activeBitmask = 0;
  keyNames: string[] = [
    'ok',
    'left',
    'right',
    'up',
    'down',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'epg',
    'info',
    'text',
    'subtitles',
    'back',
    'red',
    'green',
    'yellow',
    'blue',
    'av',
    'menu',
    'power',
    'channel_up',
    'channel_down',
  ];

  resident: Resident;
  resident$: Observable<Resident>;
  residentSubscription: Subscription;

  constructor(private logger: LoggerService, private store$: Store<State>) {
    this.resident$ = this.store$.select(UserSelectors.selectCurrentUser);
    this.residentSubscription = this.resident$.subscribe(
      (resident: Resident) => (this.resident = resident)
    );
  }

  ngOnDestroy(): void {
    this.residentSubscription.unsubscribe();
  }

  private arrayFromMask(nMask) {
    // nMask must be between -2147483648 and 2147483647
    if (nMask > 0x7fffffff || nMask < -0x80000000) {
      throw new TypeError('arrayFromMask - out of range');
    }
    for (
      var nShifted = nMask, aFromMask = [];
      nShifted;
      aFromMask.push(Boolean(nShifted & 1)), nShifted >>>= 1
    );
    return aFromMask;
  }

  private activeKeyNames(array) {
    // Expects an array of booleans representing a bitmask, as returned by arrayFromMask
    let names = [];
    for (let i = 0; i < array.length; i++) {
      let key = array[i];
      if (key) {
        names.push(this.keyNames[i]);
      }
    }
    return names;
  }

  private getSupervisorKeys() {
    let keys = 0;
    if (this.resident) {
      keys =
        REMOTE_KEYS.key_av |
        REMOTE_KEYS.key_power |
        REMOTE_KEYS.key_subtitles |
        REMOTE_KEYS.key_text |
        REMOTE_KEYS.key_green; // AV, POWER, SUBTITLES, TEXT, GREEN Key (ASSISTANCE)
    }
    // if menu_key_enabled=false, grab the key
    if (this.resident && !this.resident.set_top_box.menu_key_enabled) {
      keys = keys | REMOTE_KEYS.key_menu; // MENU
    }
    return keys;
  }

  getKeysetWithSupervisorKeys(keySet?: REMOTE_KEY_SETS) {
    let set = this.getSupervisorKeys();
    if (keySet) {
      set = set | keySet;
    }
    return set;
  }

  getActiveBitmask() {
    return this.activeBitmask;
  }

  getActiveKeyNames() {
    let mask = null;
    mask = this.activeBitmask;
    let array = this.arrayFromMask(mask);
    return this.activeKeyNames(array);
  }

  setActiveBitmask(bitmask: number) {
    this.activeBitmask = bitmask;
    console.info('RCU : bitmask set : ' + this.getActiveKeyNames());
  }
}
