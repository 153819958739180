import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { BaseUser } from '../core/models/base-user.model';
import { ConferencingService } from '../core/services/conferencing.service';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import * as ConferencingActions from './conferencing.actions';
import * as ConferencingSelectors from './conferencing.selectors';
import { CallType, ConferencingState } from './conferencing.reducer';
import { selectConferencingState } from './conferencing.selectors';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TwilioRtcDiagnosticsService } from '../core/services/twilio-rtc-diagnostics.service';
import * as StbActions from './stb.actions';

@Injectable()
export class ConferencingEffects {
  constructor(
    private actions$: Actions,
    private conferencingService: ConferencingService,
    private store$: Store<ConferencingState>,
    public dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private twilioRtcDiagnosticsService: TwilioRtcDiagnosticsService
  ) {}

  // Subscribe to conferencing channel
  subscribeConferencingChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.subscribeConferencingChannel),
        map((action) => {
          this.conferencingService.subscribe(action.customer, action.user);
        })
      ),
    { dispatch: false }
  );

  // Outbound Actions - these call conferencing service to
  // trigger events over ws channel to remote party.

  requestCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.requestCall),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) =>
          this.conferencingService.requestCall(
            state.subscription.channelName,
            action.callOptions
          )
        )
      ),
    { dispatch: false }
  );

  acceptCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingActions.acceptCall),
      concatLatestFrom(() => [
        this.store$.select(selectConferencingState),
        this.store$.select(UserSelectors.selectShowDebug),
      ]),
      map(([action, state, showDebug]) => {
        if (showDebug) {
          this.matSnackBar.open(
            `ACCEPT CALL: room: ${state.inboundCallOptions.roomName} channel: ${state.subscription.channelName}`,
            '',
            this.getSnackBarConfig()
          );
        }
        this.conferencingService.acceptCall(
          state.subscription.channelName,
          state.inboundCallOptions
        );

        return ConferencingActions.showCallViewer({
          callType: CallType.INBOUND,
          remoteUser: state.inboundCallOptions.initiator,
        });
      })
    )
  );

  declineCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingActions.declineCall),
      concatLatestFrom(() => [
        this.store$.select(selectConferencingState),
        this.store$.select(UserSelectors.selectShowDebug),
      ]),
      map(([action, state, showDebug]) => {
        if (showDebug) {
          this.matSnackBar.open(
            `DECLINE CALL: room: ${state.inboundCallOptions.roomName} channel: ${state.subscription.channelName}`,
            '',
            this.getSnackBarConfig()
          );
        }
        this.conferencingService.declineCall(
          state.subscription.channelName,
          state.inboundCallOptions,
          action.reason
        );
        if (state.acceptCall !== true) {
          return UserActions.navigateToHome();
        } else {
          return ConferencingActions.noOp();
        }
      })
    )
  );

  endCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.endCall),
        concatLatestFrom(() => [
          this.store$.select(selectConferencingState),
          this.store$.select(UserSelectors.selectShowDebug),
        ]),
        map(([action, state, showDebug]) => {
          if (showDebug) {
            this.matSnackBar.open(
              `END CALL: room: ${state.inboundCallOptions.roomName} channel: ${state.subscription.channelName}`,
              '',
              this.getSnackBarConfig()
            );
          }

          let initiator: BaseUser;
          if (action.callType === CallType.INBOUND) {
            initiator = state.inboundCallOptions.initiator;
          } else {
            initiator = state.outboundCallOptions.target;
          }
          const callOptions = { ...state.inboundCallOptions, initiator };
          this.conferencingService.endCall(
            state.subscription.channelName,
            callOptions
          );
        })
      ),
    { dispatch: false }
  );

  callEnded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingActions.callEnded),
      mergeMap((action) => [
        ConferencingActions.listenerRedirect(),
        ConferencingActions.resetState(),
      ])
    )
  );

  // Inbound actions - may be call UI components?

  acceptCallRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.acceptCallRedirect),
        map((action) => this.router.navigate(['/conferencing/accept-call']))
      ),
    { dispatch: false }
  );

  acceptCallWithDelayRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.acceptCallWithDelayRedirect),
        map((action) =>
          this.router.navigate(['/conferencing/accept-call-with-delay'])
        )
      ),
    { dispatch: false }
  );

  listenerRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.listenerRedirect),
        map((action) => this.router.navigate(['/supervisor/listener']))
      ),
    { dispatch: false }
  );

  callrequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingActions.callRequested),
      concatLatestFrom(() => [
        this.store$.select(selectConferencingState),
        this.store$.select(ConferencingSelectors.selectCameraConnected),
        this.store$.select(UserSelectors.selectSetTopBox),
      ]),
      map(([action, conferencingState, cameraConnected, setTopBox]) => {
        console.log(`call requested: ${JSON.stringify(action)}`);

        if (setTopBox.is_screen_on === false && action.callOptions.wakeScreen) {
          this.store$.dispatch(StbActions.setPowerNormal());
        }

        if (conferencingState.acceptCall === true) {
          return ConferencingActions.declineCall({ reason: 'busy' });
        } else if (!cameraConnected) {
          return ConferencingActions.declineCall({ reason: 'no-camera' });
        } else if (action.callOptions.autoAccept === true) {
          return ConferencingActions.acceptCall();
        } else if (
          action.callOptions.autoAccept === false &&
          action.callOptions.acceptAfter > 0
        ) {
          // auto accept call after delay
          return ConferencingActions.acceptCallWithDelayRedirect();
        } else {
          return ConferencingActions.acceptCallRedirect();
        }
      })
    )
  );

  showCallViewer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.showCallViewer),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          console.info(`show call viewer effect fired!`);
          return this.router.navigate([
            'conferencing/call-viewer',
            { callType: 'incoming' },
          ]);
        })
      ),
    { dispatch: false }
  );

  callAccepted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.callAccepted),
        concatLatestFrom(() => [
          this.store$.select(selectConferencingState),
          this.store$.select(UserSelectors.selectShowDebug),
        ]),
        map(([action, state, showDebug]) => {
          if (showDebug) {
            this.matSnackBar.open(
              `CALL ACCEPTED: room: ${state.inboundCallOptions.roomName} channel: ${state.subscription.channelName}`,
              '',
              this.getSnackBarConfig()
            );
          }
          console.log(`call accepted effect fired!`);
        })
      ),
    { dispatch: false }
  );

  testVideoInputDevices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.testVideoInputDevices),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          this.twilioRtcDiagnosticsService.testVideoInputDevices(
            action.options
          );
        })
      ),
    { dispatch: false }
  );

  testAudioInputDevices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.testAudioInputDevices),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          this.twilioRtcDiagnosticsService.testAudioInputDevices(
            action.options
          );
        })
      ),
    { dispatch: false }
  );

  testAudioOutputDevices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.testAudioOutputDevices),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          this.twilioRtcDiagnosticsService.testAudioOutputDevices(
            action.options
          );
        })
      ),
    { dispatch: false }
  );

  testMediaConnectionBitrate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.testMediaConnectionBitrate),
        concatLatestFrom(() => this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          this.twilioRtcDiagnosticsService.testMediaConnectionBitRate(
            action.options
          );
        })
      ),
    { dispatch: false }
  );

  getSnackBarConfig(): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.duration = 4000;
    config.horizontalPosition = 'left';
    config.verticalPosition = 'top';
    return config;
  }
}
