import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ConferencingState,
  conferencingFeatureKey,
} from './conferencing.reducer';

export const selectConferencingState = createFeatureSelector<ConferencingState>(
  conferencingFeatureKey
);

export const selectConferencingSubscription = createSelector(
  selectConferencingState,
  (state) => state.subscription
);

export const selectInboundCallOptions = createSelector(
  selectConferencingState,
  (state) => state.inboundCallOptions
);

export const selectOutboundCallOptions = createSelector(
  selectConferencingState,
  (state) => state.outboundCallOptions
);

export const selectCallAccepted = createSelector(
  selectConferencingState,
  (state) => state.callAccepted
);
export const selectCallDeclined = createSelector(
  selectConferencingState,
  (state) => state.callDeclined
);
export const selectCallEnded = createSelector(
  selectConferencingState,
  (state) => state.callEnded
);

export const selectAcceptCall = createSelector(
  selectConferencingState,
  (state) => state.acceptCall
);

export const selectVideoInputDiagnostics = createSelector(
  selectConferencingState,
  (state) => state.videoInputDiagnostics
);

export const selectAudioInputDiagnostics = createSelector(
  selectConferencingState,
  (state) => state.audioInputDiagnostics
);

export const selectAudioOutputDiagnostics = createSelector(
  selectConferencingState,
  (state) => state.audioOutputDiagnostics
);

export const selectMediaConnectionBitrateDiagnostics = createSelector(
  selectConferencingState,
  (state) => state.mediaConnectionBitrateDiagnostics
);

export const selectCameraConnected = createSelector(
  selectConferencingState,
  (state) =>
    state.videoInputDiagnostics &&
    state.videoInputDiagnostics.report &&
    state.videoInputDiagnostics.report.errors.length === 0
);
