import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Renderer2,
  Input,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  CallOptions,
  ConferencingState,
} from 'src/app/state/conferencing.reducer';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';
import * as ConferencingActions from 'src/app/state/conferencing.actions';
import { selectCallEnded } from 'src/app/state/conferencing.selectors';
import { MatDialogRef } from '@angular/material/dialog';
import { IdcapService } from '../../../core/services/idcap.service';

@Component({
  selector: 'cm-accept-call-dialog',
  templateUrl: './accept-call-dialog.component.html',
  styleUrls: ['./accept-call-dialog.component.scss'],
})
export class AcceptCallDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  choices: Array<any> = [];
  dialogRef: MatDialogRef<AcceptCallDialogComponent>;
  callEnded$: Observable<boolean>;
  callEndedSubscription: Subscription;
  media: any;

  ringingAudio = new Audio('assets/sounds/ringing.mp3');
  acceptedAudio = new Audio('assets/sounds/positive.wav');
  declinedAudio = new Audio('assets/sounds/declined.mp3');

  @Input() callOptions: CallOptions;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private store$: Store<ConferencingState>,
    private idcapService: IdcapService
  ) {}

  ngOnInit(): void {
    // setup audio
    this.ringingAudio.load();
    this.ringingAudio.loop = true;
    this.acceptedAudio.load();
    this.declinedAudio.load();

    // add choices
    this.choices = [
      { id: 1, label: '', icon: 'cm:tick-circle' },
      { id: 2, label: '', icon: 'cm:cross-circle' },
    ];

    this.callEnded$ = this.store$.select(selectCallEnded);
    this.callEndedSubscription = this.callEnded$.subscribe((ended: boolean) => {
      if (ended) {
        this.callEnded();
      }
    });
  }

  ngAfterViewInit(): void {
    this.playRingingSound();
  }

  ngOnDestroy(): void {
    this.callEndedSubscription.unsubscribe();
  }

  async onChoiceSelected(choice): Promise<void> {
    if (choice.id === 1) {
      await this.accept();
    } else {
      await this.decline();
    }
  }

  async accept(): Promise<void> {
    console.info('accept call clicked');
    this.store$.dispatch(ConferencingActions.acceptCall());
    this.stopRingingSound();
    await this.playCallAcceptedSound();
    await this.router.navigate([
      'conferencing/call-viewer',
      { callType: 'incoming' },
    ]);
  }

  async decline($event?): Promise<void> {
    console.info('decline call clicked');
    this.stopRingingSound();
    await this.playCallDeclinedSound();
    this.store$.dispatch(
      ConferencingActions.declineCall({ reason: 'user-decline' })
    );
    this.store$.dispatch(ConferencingActions.resetState());
    await this.idcapService.resetAV();
  }

  async callEnded(): Promise<void> {
    this.stopRingingSound();
    await this.playCallDeclinedSound();
    this.store$.dispatch(ConferencingActions.resetState());
    await this.idcapService.resetAV();
    this.close();
  }

  close($event?): void {
    this.store$.dispatch(UserActions.navigateToHome());
  }

  playRingingSound(): Promise<void> {
    return this.ringingAudio
      .play()
      .then((value) => console.log(`playing ringing sound`))
      .catch((error) =>
        console.log(`failed to play ringing sound: ${JSON.stringify(error)}`)
      );
  }

  stopRingingSound(): void {
    this.ringingAudio.pause();
  }

  playCallAcceptedSound(): Promise<void> {
    return this.acceptedAudio
      .play()
      .then((value) => console.log(`playing accepted sound`))
      .catch((error) =>
        console.log(`failed to play accepted sound: ${JSON.stringify(error)}`)
      );
  }

  stopCallAcceptedSound(): void {
    this.acceptedAudio.pause();
  }

  playCallDeclinedSound(): Promise<void> {
    return this.declinedAudio
      .play()
      .then((value) => console.log(`playing declined sound`))
      .catch((error) =>
        console.log(`failed to play declined sound: ${JSON.stringify(error)}`)
      );
  }

  stopCallDeclinedSound(): void {
    this.declinedAudio.pause();
  }
}
