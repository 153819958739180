import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';

import { ConferencingRoutingModule } from './conferencing-routing.module';
import { AcceptCallComponent } from './accept-call/accept-call.component';
import { CallComponent } from './call/call.component';
import { RequestCallDialogComponent } from './request-call-dialog/request-call-dialog.component';
import { RequestCallConfirmationDialogComponent } from './request-call-confirmation-dialog/request-call-confirmation-dialog.component';
import { AcceptCallWithDelayComponent } from './accept-call-with-delay/accept-call-with-delay.component';

@NgModule({
  imports: [SharedModule, ConferencingRoutingModule],
  declarations: [
    AcceptCallComponent,
    CallComponent,
    RequestCallDialogComponent,
    RequestCallConfirmationDialogComponent,
    AcceptCallWithDelayComponent,
  ],
})
export class ConferencingModule {}
