import { createReducer, on } from '@ngrx/store';
import * as ConferencingActions from './conferencing.actions';
import {
  AudioInputTest,
  AudioOutputTest,
  DiagnosticError,
  MediaConnectionBitrateTest,
  VideoInputTest,
  WarningName,
} from '@twilio/rtc-diagnostics';

export const conferencingFeatureKey = 'conferencing';

export enum CallType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export interface ConferencingSubscription {
  channelName: string;
  connected: boolean;
  message: string;
}

export interface CallOptions {
  initiator?: any;
  target?: any;
  audioOnly?: boolean;
  declineReason?: string;
  roomName?: string;
  roomSid?: string;
  autoAccept?: boolean;
  acceptAfter?: number;
  wakeScreen?: boolean;
}

export interface VideoInputDiagnostics {
  error: DiagnosticError;
  report: VideoInputTest.Report;
}

export interface AudioInputDiagnostics {
  volume: number;
  warning: WarningName;
  error: DiagnosticError;
  report: AudioInputTest.Report;
}

export interface AudioOutputDiagnostics {
  volume: number;
  error: DiagnosticError;
  report: AudioOutputTest.Report;
}

export interface MediaConnectionBitrateDiagnostics {
  bitrate: number;
  warning: WarningName;
  error: DiagnosticError;
  report: MediaConnectionBitrateTest.Report;
}

export interface ConferencingState {
  videoInputDiagnostics: VideoInputDiagnostics;
  audioInputDiagnostics: AudioInputDiagnostics;
  audioOutputDiagnostics: AudioOutputDiagnostics;
  mediaConnectionBitrateDiagnostics: MediaConnectionBitrateDiagnostics;
  subscription: ConferencingSubscription;
  inboundCallOptions: CallOptions;
  outboundCallOptions: CallOptions;
  callAccepted: boolean;
  callDeclined: boolean;
  callEnded: boolean;
  acceptCall: boolean;
  declineCall: boolean;
  endCall: boolean;
}

const initialState: ConferencingState = {
  videoInputDiagnostics: undefined,
  audioInputDiagnostics: undefined,
  audioOutputDiagnostics: undefined,
  mediaConnectionBitrateDiagnostics: undefined,
  subscription: { channelName: '', connected: false, message: '' },
  inboundCallOptions: undefined,
  outboundCallOptions: undefined,
  callAccepted: false,
  callDeclined: false,
  callEnded: false,
  acceptCall: false,
  declineCall: false,
  endCall: false,
};

export const conferencingReducer = createReducer<ConferencingState>(
  initialState,
  on(
    ConferencingActions.subscribeConferencingChannelSuccess,
    (state: ConferencingState, action) => ({
      ...state,
      subscription: action.subscription,
    })
  ),
  on(
    ConferencingActions.subscribeConferencingChannelFailure,
    (state, action) => ({ ...state, subscriptionError: action.subscription })
  ),
  on(ConferencingActions.callRequested, (state: ConferencingState, action) => ({
    ...state,
    inboundCallOptions: action.callOptions,
  })),
  on(ConferencingActions.callAccepted, (state: ConferencingState, action) => ({
    ...state,
    callAccepted: true,
  })),
  on(ConferencingActions.callDeclined, (state: ConferencingState, action) => ({
    ...state,
    callDeclined: true,
  })),
  on(ConferencingActions.callEnded, (state: ConferencingState, action) => ({
    ...state,
    callEnded: true,
  })),
  on(ConferencingActions.acceptCall, (state: ConferencingState, action) => ({
    ...state,
    acceptCall: true,
  })),
  on(ConferencingActions.declineCall, (state: ConferencingState, action) => ({
    ...state,
    declineCall: true,
  })),
  on(ConferencingActions.endCall, (state: ConferencingState, action) => ({
    ...state,
    endCall: true,
  })),
  on(ConferencingActions.resetState, (state: ConferencingState, action) => ({
    ...state,
    inboundCallOptions: undefined,
    outboundCallOptions: undefined,
    callAccepted: false,
    callDeclined: false,
    callEnded: false,
    acceptCall: false,
    declineCall: false,
    endCall: false,
  })),
  on(ConferencingActions.requestCall, (state: ConferencingState, action) => ({
    ...state,
    outboundCallOptions: action.callOptions,
  })),
  on(
    ConferencingActions.testVideoInputDevicesError,
    (state: ConferencingState, action) => {
      return {
        ...state,
        videoInputDiagnostics: {
          ...state.videoInputDiagnostics,
          error: action.error,
        },
      };
    }
  ),
  on(
    ConferencingActions.testVideoInputDevicesEnd,
    (state: ConferencingState, action) => {
      return {
        ...state,
        videoInputDiagnostics: {
          ...state.videoInputDiagnostics,
          report: action.report,
        },
      };
    }
  ),
  on(
    ConferencingActions.testAudioInputDevicesVolume,
    (state: ConferencingState, action) => ({
      ...state,
      audioInputDiagnostics: {
        ...state.audioInputDiagnostics,
        volume: action.volume,
      },
    })
  ),
  on(
    ConferencingActions.testAudioInputDevicesWarning,
    (state: ConferencingState, action) => ({
      ...state,
      audioInputDiagnostics: {
        ...state.audioInputDiagnostics,
        warning: action.warningName,
      },
    })
  ),
  on(
    ConferencingActions.testAudioInputDevicesWarningCleared,
    (state: ConferencingState, action) => ({
      ...state,
      audioInputDiagnostics: {
        ...state.audioInputDiagnostics,
        warning: action.warningName,
      },
    })
  ),
  on(
    ConferencingActions.testAudioInputDevicesError,
    (state: ConferencingState, action) => ({
      ...state,
      audioInputDiagnostics: {
        ...state.audioInputDiagnostics,
        error: action.error,
      },
    })
  ),
  on(
    ConferencingActions.testAudioInputDevicesEnd,
    (state: ConferencingState, action) => ({
      ...state,
      audioInputDiagnostics: {
        ...state.audioInputDiagnostics,
        report: action.report,
      },
    })
  ),
  on(
    ConferencingActions.testAudioOutputDevicesVolume,
    (state: ConferencingState, action) => ({
      ...state,
      audioOutputDiagnostics: {
        ...state.audioOutputDiagnostics,
        volume: action.volume,
      },
    })
  ),
  on(
    ConferencingActions.testAudioOutputDevicesError,
    (state: ConferencingState, action) => ({
      ...state,
      audioOutputDiagnostics: {
        ...state.audioOutputDiagnostics,
        error: action.error,
      },
    })
  ),
  on(
    ConferencingActions.testAudioOutputDevicesEnd,
    (state: ConferencingState, action) => ({
      ...state,
      audioOutputDiagnostics: {
        ...state.audioOutputDiagnostics,
        report: action.report,
      },
    })
  ),
  on(
    ConferencingActions.testMediaConnectionBitrateBitrate,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: {
        ...state.mediaConnectionBitrateDiagnostics,
        bitrate: action.bitrate,
      },
    })
  ),
  on(
    ConferencingActions.testMediaConnectionBitrateWarning,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: {
        ...state.mediaConnectionBitrateDiagnostics,
        warning: action.warningName,
      },
    })
  ),
  on(
    ConferencingActions.testMediaConnectionBitrateWarningCleared,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: {
        ...state.mediaConnectionBitrateDiagnostics,
        warning: undefined,
      },
    })
  ),
  on(
    ConferencingActions.testMediaConnectionBitrateError,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: {
        ...state.mediaConnectionBitrateDiagnostics,
        error: action.error,
      },
    })
  ),
  on(
    ConferencingActions.testMediaConnectionBitrateEnd,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: {
        ...state.mediaConnectionBitrateDiagnostics,
        report: action.report,
      },
    })
  ),
  on(
    ConferencingActions.clearMediaConnectionBitrateTestResult,
    (state: ConferencingState, action) => ({
      ...state,
      mediaConnectionBitrateDiagnostics: undefined,
    })
  )
);
