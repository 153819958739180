/**
 * Permits a type T to be null.
 */
declare var hcap: any;

export type Nullable<T> = T | null;

export enum ExternalInput {
  TV = 'TV',
  HDMI = 'HDMI',
  COMPOSITE = 'COMPOSITE',
  SVIDEO = 'SVIDEO',
  COMPONENT = 'COMPONENT',
  RGB = 'RGB',
  SCART = 'SCART',
  OTHERS = 'OTHERS',
}

export interface ExternalInputSource {
  inputPort: string;
  signalDetection: boolean;
  type: ExternalInput;
  name: string;
  index: number;
}

export interface ExternalInputResponse {
  inputSourceList: ExternalInputSource[];
  count: number;
  currentInputPort: string;
}
